/* #e0f5f4 */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #fffeff;
  color: #143d50;
  font-weight: 400;
  font-family: "Untitled Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

.App {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.App::-webkit-scrollbar {
  display: none;
}

:root {
  --primary: #0aa89e;
  --secondary: #ff4f1e;
  --bgColor: #f2f8fa;
  --tertiary-bg: #ff4f1e0d;
  --text-color: #143d50;
  --orangeGradient: linear-gradient(90deg, #e72429 33%, #f76400 66%);
  --blueGradient: linear-gradient(90deg, #287a9f 0%, #156380 100%);
  --whiteGradient: linear-gradient(90deg, #f2f8fa 0%, #fff 50%);
  --shadow: 0 20px 50px 10px rgb(20 61 80 / 15%);
  --headerFont: "'Ubuntu", serif;
  --textFont: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  max-width: 100%;
}

.content-wrapper {
  padding: 32px 95px;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.text-13 {
  font-size: 13px;
}

@media screen and (min-width: 765px) and (max-width: 1200px) {
  .content-wrapper {
    padding: 0 64px;
  }
}

@media screen and (max-width: 760px) {
  .content-wrapper {
    padding: 0 16px;
  }
}
